import { View, Flex } from '@aws-amplify/ui-react';
import { Fade } from 'react-reveal';
import { Icon } from '../components/Icon';
import { Card } from '../components/Card';
import { PointFeature } from '../components/Amplify-ui';
import styled from 'styled-components';

import Digital from 'assets/images/icons/digital.svg';
import Competitor from 'assets/images/icons/competitor.svg';
import Audience from 'assets/images/icons/audience.svg';
import Viral from 'assets/images/icons/viral.svg';
import Individu from 'assets/images/icons/individu.svg';
import Influencer from 'assets/images/icons/influencer.svg';
import Bag from 'assets/images/icons/bag.svg';
import CompanyBag from 'assets/images/icons/company-bag.svg';
import Trending from 'assets/images/icons/trending.svg';
import WhyDigital from 'assets/images/why-digital.svg';
import WhyCompetitor from 'assets/images/why-competitor.svg';
import WhyAudiences from 'assets/images/why-audiences.svg';
import WhyViral from 'assets/images/why-viral.svg';
import UseAlphalitical from 'assets/images/use-alphalitical.svg';
import UseAlphaliticalStripe from 'assets/images/use-alphalitical-stripe.svg';
import { ButtonPrimary } from '../components/Button';
import { LottiePlayer, LottieWrapper } from '../components/Lottie';
import { GradientLottie } from '../utils/constants';
import NoSSR from '@/components/NoSSR';
import { BackgroundHero } from '@/components/BackgroundHero/BackgroundHero';
import PageLayout from '@/components/PageLayout/PageLayout';
import { H1, Paragraph, H3, H2 } from '@/components/Typography/Typography';
import { PricingSection } from 'modules/pricing';

const HeroWrapper = styled(Flex)`
  width: 100%;
  height: 100vh;
  gap: 0;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  iframe {
    box-shadow: 0px 30px 60px 0px #756f9e29;
  }

  @media only screen and (min-width: 1024px) {
    padding-left: 60px;
    padding-right: 60px;

    iframe {
      width: 420px;
      height: 300px;
    }
  }

  @media only screen and (min-width: 1280px) {
    iframe {
      width: 600px;
      height: 350px;
    }
  }

  @media only screen and (min-width: 1440px) {
    iframe {
      width: 700px;
      height: 400px;
    }
  }
`;

const FeaturesWrapper = styled(View)`
  &::before {
    display: block;
    content: ' ';
    height: 80px;
    margin-top: -80px;
    visibility: hidden;
  }

  @media only screen and (min-width: 1024px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media only screen and (min-width: 1280px) {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

const WhyWrapper = styled(View)`
  @media only screen and (min-width: 1024px) {
    padding-top: 160px;
    padding-left: 60px;
    padding-right: 60px;
  }

  @media only screen and (min-width: 1280px) {
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;
  }
`;

const MainWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  width: 100%;
`;

const Main = () => {
  return (
    <div className="flex flex-col items-center">
      <BackgroundHero />

      <MainWrapper>
        {/** Hero Landing */}
        <HeroWrapper id="home">
          <View display="flex" gap={24} margin="0 auto">
            <Flex padding="12px 24px" direction="column" width="100%" maxWidth={900} justifyContent="center" gap="16px">
              <H1 style={{ fontFamily: 'Alphasans-ExtraBold' }} margin="0" className="hidden md:block">
                Getting to know your business&apos; digital footprint together
              </H1>
              <H2 style={{ fontFamily: 'Alphasans-ExtraBold' }} margin="0" className="md:hidden">
                Getting to know your business&apos; digital footprint together
              </H2>
              <Paragraph margin="0">
                The easier way to get the best strategy for your business through our machine learning technology.
              </Paragraph>
            </Flex>
            <Flex
              display="none"
              position="relative"
              direction="column"
              alignItems="flex-end"
              justifyContent="center"
              width="-webkit-fill-"
            >
              <iframe
                src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ borderRadius: '16px' }}
              ></iframe>
            </Flex>
          </View>
        </HeroWrapper>

        {/** Our Features */}
        <Fade duration={1000} bottom>
          <FeaturesWrapper id="features">
            <Fade duration={1000} bottom>
              <Flex justifyContent="center">
                <H1 style={{ fontFamily: 'Alphasans-ExtraBold' }} className="hidden md:block">
                  Our Features
                </H1>
                <H2 style={{ fontFamily: 'Alphasans-ExtraBold' }} className="md:hidden">
                  Our Features
                </H2>
              </Flex>
              <LottieWrapper left="-30%" marginRight="30%">
                <LottiePlayer renderer="svg" autoplay loop src={GradientLottie} />
              </LottieWrapper>
              {/* <View display="flex" style={{ flexFlow: 'wrap' }} justifyContent="center"> */}
              <View className="flex flex-wrap justify-center max-sm:hidden">
                <Card width="30%">
                  <Icon IconComponent={Digital} />
                  <H3 margin="32px 0px 0px 0px">Digital Data Monitoring</H3>
                  <Paragraph>
                    Observe how your project grows in digital among general audiences. See your project’s reach and
                    engagement
                  </Paragraph>
                </Card>
                <Card width="30%">
                  <Icon IconComponent={Competitor} />
                  <H3 margin="32px 0px 0px 0px">Competitor Monitoring</H3>
                  <Paragraph>
                    Keep track of your competitor&apos;s digital progress to keep up with the competition.
                  </Paragraph>
                </Card>
                <Card width="30%">
                  <Icon IconComponent={Audience} />
                  <H3 margin="32px 0px 0px 0px">Audience</H3>
                  <Paragraph>Identify the audience of your project better!</Paragraph>
                </Card>
                <Card width="30%">
                  <Icon IconComponent={Viral} />
                  <H3 margin="32px 0px 0px 0px">What&apos;s Viral?</H3>
                  <Paragraph>
                    Never FOMO! We will update you with viral contents and their performance on Digital
                  </Paragraph>
                </Card>
                <Card width="30%">
                  <Icon IconComponent={Trending} />
                  <H3 margin="32px 0px 0px 0px">Trending Now</H3>
                  <Paragraph>Never miss an opportunity to hop on the latest trend with your business!</Paragraph>
                </Card>
              </View>
              <View className="flex flex-wrap justify-center md:hidden">
                <Card width="85%" marginRight="0">
                  <Icon IconComponent={Digital} />
                  <H3 margin="32px 0px 0px 0px">Digital Data Monitoring</H3>
                  <Paragraph>
                    Observe how your project grows in digital among general audiences. See your project’s reach and
                    engagement
                  </Paragraph>
                </Card>
                <Card width="85%" marginRight="0">
                  <Icon IconComponent={Competitor} />
                  <H3 margin="32px 0px 0px 0px">Competitor Monitoring</H3>
                  <Paragraph>
                    Keep track of your competitor&apos;s digital progress to keep up with the competition.
                  </Paragraph>
                </Card>
                <Card width="85%" marginRight="0">
                  <Icon IconComponent={Audience} />
                  <H3 margin="32px 0px 0px 0px">Audience</H3>
                  <Paragraph>Identify the audience of your project better!</Paragraph>
                </Card>
                <Card width="85%" marginRight="0">
                  <Icon IconComponent={Viral} />
                  <H3 margin="32px 0px 0px 0px">What&apos;s Viral?</H3>
                  <Paragraph>
                    Never FOMO! We will update you with viral contents and their performance on Digital
                  </Paragraph>
                </Card>
                <Card width="85%" marginRight="0">
                  <Icon IconComponent={Trending} />
                  <H3 margin="32px 0px 0px 0px">Trending Now</H3>
                  <Paragraph>Never miss an opportunity to hop on the latest trend with your business!</Paragraph>
                </Card>
              </View>
            </Fade>
          </FeaturesWrapper>
        </Fade>

        {/** Why Alphalitical */}
        <WhyWrapper id="whyAlphalitical">
          <Fade duration={1000} bottom>
            <Flex justifyContent="center" marginBottom={48}>
              <H1 style={{ fontFamily: 'Alphasans-ExtraBold' }} className="hidden md:block">
                Why Alphalitical?
              </H1>
              <H2 style={{ fontFamily: 'Alphasans-ExtraBold' }} className="md:hidden">
                Why Alphalitical?
              </H2>
            </Flex>
          </Fade>
          <Flex direction="column" className="hidden md:block">
            <Fade duration={1000} bottom>
              <Flex alignItems="center" justifyContent="space-evenly">
                <View>
                  <PointFeature>Digital</PointFeature>
                  <H1 margin="0">Monitor your digital project with ease</H1>
                  <Paragraph>As easy as posting social media content with deep data-driven understanding.</Paragraph>
                </View>
                <WhyDigital className="why-alphalitical-svg" />
              </Flex>
              <Flex alignItems="center" justifyContent="space-evenly">
                <WhyCompetitor className="why-alphalitical-svg" />
                <View>
                  <PointFeature>
                    <div style={{ color: '#FF9500' }}>Competitor</div>
                  </PointFeature>
                  <H1 margin="0">Competitor Performance</H1>
                  <Paragraph>
                    Recognize your competitor’s strength by monitoring them on their digital journey.
                  </Paragraph>
                </View>
              </Flex>
              <Flex alignItems="center" justifyContent="space-evenly">
                <View>
                  <PointFeature>
                    <div style={{ color: '#32ADE6' }}>Audiences</div>
                  </PointFeature>
                  <H1 margin="0">Identify your audience</H1>
                  <Paragraph>
                    Be the master of your campaign&apos;s conversation by understanding your market&apos;s potential.
                  </Paragraph>
                </View>
                <WhyAudiences className="why-alphalitical-svg" />
              </Flex>
              <Flex alignItems="center" justifyContent="space-evenly">
                <WhyViral className="why-alphalitical-svg" />
                <View>
                  <PointFeature>
                    <div style={{ color: '#AF52DE' }}>Viral</div>
                  </PointFeature>
                  <H1 margin="0">Viral Content</H1>
                  <Paragraph>Get on top of the trends and make your project run the show.</Paragraph>
                </View>
                <LottieWrapper top="50%" marginLeft="50%">
                  <LottiePlayer renderer="svg" autoplay loop src={GradientLottie} />
                </LottieWrapper>
              </Flex>
            </Fade>
          </Flex>
          <Flex direction="column" className="md:hidden mx-8">
            <Fade duration={1000} bottom>
              <Flex alignItems="center" justifyContent="space-evenly" direction={'column'}>
                <View>
                  <PointFeature>Digital</PointFeature>
                  <H2 margin="0">Monitor your digital project with ease</H2>
                  <Paragraph>As easy as posting social media content with deep data-driven understanding.</Paragraph>
                </View>
                <WhyDigital className="why-alphalitical-svg max-sm:!w-auto max-sm:!h-auto" />
              </Flex>
              <Flex alignItems="center" justifyContent="space-evenly" direction={'column-reverse'}>
                <WhyCompetitor className="why-alphalitical-svg max-sm:!w-auto max-sm:!h-auto" />
                <View>
                  <PointFeature>
                    <div style={{ color: '#FF9500' }}>Competitor</div>
                  </PointFeature>
                  <H2 margin="0">Competitor Performance</H2>
                  <Paragraph>
                    Recognize your competitor’s strength by monitoring them on their digital journey.
                  </Paragraph>
                </View>
              </Flex>
              <Flex alignItems="center" justifyContent="space-evenly" direction={'column'}>
                <View>
                  <PointFeature>
                    <div style={{ color: '#32ADE6' }}>Audiences</div>
                  </PointFeature>
                  <H2 margin="0">Identify your audience</H2>
                  <Paragraph>
                    Be the master of your campaign&apos;s conversation by understanding your market&apos;s potential.
                  </Paragraph>
                </View>
                <WhyAudiences className="why-alphalitical-svg max-sm:!w-auto max-sm:!h-auto" />
              </Flex>
              <Flex alignItems="center" justifyContent="space-evenly" direction={'column-reverse'}>
                <WhyViral className="why-alphalitical-svg max-sm:!w-auto max-sm:!h-auto" />
                <View>
                  <PointFeature>
                    <div style={{ color: '#AF52DE' }}>Viral</div>
                  </PointFeature>
                  <H2 margin="0">Viral Content</H2>
                  <Paragraph>Get on top of the trends and make your project run the show.</Paragraph>
                </View>
                <LottieWrapper top="50%" marginLeft="50%">
                  <LottiePlayer renderer="svg" autoplay loop src={GradientLottie} />
                </LottieWrapper>
              </Flex>
            </Fade>
          </Flex>
        </WhyWrapper>

        {/** Everyone can use Alphalitical! */}
        <View width="100%">
          <Fade duration={1000} bottom>
            <Flex
              paddingBottom={64}
              paddingTop={80}
              justifyContent="center"
              borderRadius={32}
              className="hidden md:block"
            >
              <View width="100%" borderRadius={32} backgroundColor="#183B56" padding={64} position="relative">
                <UseAlphalitical className="use-alphalitical-svg" style={{ position: 'absolute', top: '-60px' }} />
                <Flex alignItems="center" direction="column">
                  <H1 color="white">Everyone can use Alphalitical!</H1>
                  <UseAlphaliticalStripe
                    style={{ marginTop: '-24px', marginBottom: '64px', width: '315px', height: '12px' }}
                  />
                </Flex>
                <Flex marginTop={64} direction="row" justifyContent="space-evenly">
                  <Flex gap={12} direction="column" alignItems="center" width={250}>
                    <Individu className="use-alphalitical-icons" style={{ zIndex: '1', marginBottom: '28px' }} />
                    <H3 margin="0" textAlign="center" color="white">
                      Personal
                    </H3>
                    <Paragraph margin="0" color="white" textAlign="center">
                      Be informed about your business using our machine learning technology that streamlines the
                      research process of your brand.
                    </Paragraph>
                  </Flex>
                  <Flex gap={12} direction="column" alignItems="center" width={250}>
                    <Influencer className="use-alphalitical-icons" style={{ zIndex: '1', marginBottom: '28px' }} />
                    <H3 margin="0" textAlign="center" color="white">
                      Content Creator
                    </H3>
                    <Paragraph margin="0" color="white" textAlign="center">
                      Analyze your content&apos;s performance and improve them to reach more audience.
                    </Paragraph>
                  </Flex>
                  <Flex gap={12} direction="column" alignItems="center" width={250}>
                    <Bag className="use-alphalitical-icons" style={{ zIndex: '1', marginBottom: '28px' }} />
                    <H3 margin="0" textAlign="center" color="white">
                      Small Business
                    </H3>
                    <Paragraph margin="0" color="white" textAlign="center">
                      Market to a wider audience and gain a competitive advantage by keeping up with the latest trends
                      in the industry.
                    </Paragraph>
                  </Flex>
                  <Flex gap={12} direction="column" alignItems="center" width={250}>
                    <CompanyBag className="use-alphalitical-icons" style={{ zIndex: '1', marginBottom: '28px' }} />
                    <H3 margin="0" textAlign="center" color="white">
                      Big Company
                    </H3>
                    <Paragraph margin="0" color="white" textAlign="center">
                      Keep up on your customer&apos;s view about your products so you can continue to innovate and keep
                      your business relevant.
                    </Paragraph>
                  </Flex>
                  <LottieWrapper marginLeft="-50%" top="-20%">
                    <LottiePlayer renderer="svg" autoplay loop src={GradientLottie} />
                  </LottieWrapper>
                </Flex>
              </View>
            </Flex>
            <Flex paddingBottom={0} paddingTop={80} justifyContent="center" borderRadius={32} className="md:hidden">
              <View
                width="100%"
                borderRadius={32}
                backgroundColor="#183B56"
                paddingBlock={64}
                paddingInline={32}
                position="relative"
              >
                <UseAlphalitical
                  className="alphalitical-svg object-cover"
                  style={{ position: 'absolute', top: '-60px' }}
                />
                <Flex alignItems="center" direction="column">
                  <H2 color="white" textAlign="center">
                    Everyone can use Alphalitical!
                  </H2>
                  <UseAlphaliticalStripe
                    style={{ marginTop: '-24px', marginBottom: '0px', width: 'auto', height: '12px' }}
                  />
                </Flex>
                <Flex marginTop={64} gap={64} direction="column" justifyContent="center" alignItems={'center'}>
                  <Flex gap={12} direction="column" alignItems="center" width={250}>
                    <Individu className="use-alphalitical-icons" style={{ zIndex: '1', marginBottom: '28px' }} />
                    <H3 margin="0" textAlign="center" color="white">
                      Personal
                    </H3>
                    <Paragraph margin="0" color="white" textAlign="center">
                      Be informed about your business using our machine learning technology that streamlines the
                      research process of your brand.
                    </Paragraph>
                  </Flex>
                  <Flex gap={12} direction="column" alignItems="center" width={250}>
                    <Influencer className="use-alphalitical-icons" style={{ zIndex: '1', marginBottom: '28px' }} />
                    <H3 margin="0" textAlign="center" color="white">
                      Content Creator
                    </H3>
                    <Paragraph margin="0" color="white" textAlign="center">
                      Analyze your content&apos;s performance and improve them to reach more audience.
                    </Paragraph>
                  </Flex>
                  <Flex gap={12} direction="column" alignItems="center" width={250}>
                    <Bag className="use-alphalitical-icons" style={{ zIndex: '1', marginBottom: '28px' }} />
                    <H3 margin="0" textAlign="center" color="white">
                      Small Business
                    </H3>
                    <Paragraph margin="0" color="white" textAlign="center">
                      Market to a wider audience and gain a competitive advantage by keeping up with the latest trends
                      in the industry.
                    </Paragraph>
                  </Flex>
                  <Flex gap={12} direction="column" alignItems="center" width={250}>
                    <CompanyBag className="use-alphalitical-icons" style={{ zIndex: '1', marginBottom: '28px' }} />
                    <H3 margin="0" textAlign="center" color="white">
                      Big Company
                    </H3>
                    <Paragraph margin="0" color="white" textAlign="center">
                      Keep up on your customer&apos;s view about your products so you can continue to innovate and keep
                      your business relevant.
                    </Paragraph>
                  </Flex>
                  <LottieWrapper marginLeft="-50%" top="-20%">
                    <LottiePlayer renderer="svg" autoplay loop src={GradientLottie} />
                  </LottieWrapper>
                </Flex>
              </View>
            </Flex>
          </Fade>
        </View>

        {/** What they said about us? */}
        {/* <Fade duration={1000} bottom>
        <Flex direction="column" alignItems="center" paddingBottom={64}>
          <H1>What do they said about us?</H1>
          <Flex direction="row">
            <Card justifyContent="space-between">
              <Quote width="40px" height="40px" />
              <Paragraph>
                We've been able to leverage our outsourced care partners and lower our cost per contact because of the
                rigor and processes we could not have implemented without Cepirit.
              </Paragraph>
              <Flex direction="row" alignItems="center">
                <img src={require(`./assets/images/avatar/avatar1.png`)} alt="avatar1" />
                <Flex direction="column" gap={1}>
                  <H5 margin="0">Dara Adelia</H5>
                  <Paragraph margin="0">Backpacker</Paragraph>
                </Flex>
              </Flex>
            </Card>
            <Card justifyContent="space-between">
              <Quote width="40px" height="40px" />
              <Paragraph>
                We've been able to leverage our outsourced care partners and lower our cost per contact because of the
                rigor and processes we could not have implemented without Cepirit.
              </Paragraph>
              <Flex direction="row" alignItems="center">
                <img src={require(`./assets/images/avatar/avatar2.png`)} alt="avatar2" />
                <Flex direction="column" gap={1}>
                  <H5 margin="0">Dara Adelia</H5>
                  <Paragraph margin="0">Backpacker</Paragraph>
                </Flex>
              </Flex>
            </Card>
            <Card justifyContent="space-between">
              <Quote width="40px" height="40px" />
              <Paragraph>
                We've been able to leverage our outsourced care partners and lower our cost per contact because of the
                rigor and processes we could not have implemented without Cepirit.
              </Paragraph>
              <Flex direction="row" alignItems="center">
                <img src={require(`./assets/images/avatar/avatar3.png`)} alt="avatar3" />
                <Flex direction="column" gap={1}>
                  <H5 margin="0">Dara Adelia</H5>
                  <Paragraph margin="0">Backpacker</Paragraph>
                </Flex>
              </Flex>
            </Card>
          </Flex>
        </Flex>
      </Fade> */}

        {/** Companies Logo */}
        {/* <Fade duration={1000} bottom>
        <Flex padding="0 300px" paddingBottom="64px">
          <img width="100%" src={require(`./assets/images/companies.png`)} alt="companies" />
        </Flex>
      </Fade> */}
        {/* <div className="w-full z-10">
          <Fade duration={1000} bottom>
            <PricingSection />
          </Fade>
        </div> */}
        {/* Offering - Start your monitoring with Alphalitical now! */}
        <View width="100%">
          <Fade duration={1000} bottom>
            <Flex
              paddingBottom={64}
              paddingTop={80}
              justifyContent="center"
              borderRadius={32}
              className="hidden md:block"
            >
              <View width="100%" borderRadius={32} backgroundColor="#183B56" padding={64} position="relative">
                <UseAlphalitical
                  className="use-alphalitical-svg object-cover"
                  style={{ position: 'absolute', top: '-200px', zIndex: 1 }}
                />
                <Flex alignItems="center" direction="column">
                  <H1 textAlign="center" color="white">
                    Let Alphalitical keep an eye on your business!
                  </H1>
                </Flex>
                <Flex marginTop={32} direction="column" alignItems="center">
                  <a style={{ all: 'unset', zIndex: 20 }} href="/contact-us">
                    <ButtonPrimary style={{ zIndex: 4 }} padding={22}>
                      Try Alphalitical now!
                    </ButtonPrimary>
                  </a>
                </Flex>
              </View>
            </Flex>
            <Flex paddingBottom={24} paddingTop={0} justifyContent="center" borderRadius={32} className="md:hidden">
              <View width="100%" borderRadius={32} backgroundColor="#183B56" padding={64} position="relative">
                <UseAlphalitical
                  className="alphalitical-svg object-cover"
                  style={{ position: 'absolute', top: '-60px', zIndex: 1 }}
                />
                <Flex alignItems="center" direction="column">
                  <H2 textAlign="center" color="white">
                    Let Alphalitical keep an eye on your business!
                  </H2>
                </Flex>
                <Flex marginTop={32} direction="column" alignItems="center">
                  <a style={{ all: 'unset', zIndex: 20 }} href="/contact-us">
                    <ButtonPrimary style={{ zIndex: 4 }} padding={22} size="small">
                      Try Alphalitical now!
                    </ButtonPrimary>
                  </a>
                </Flex>
              </View>
            </Flex>
          </Fade>
        </View>
      </MainWrapper>
    </div>
  );
};

export default Main;
